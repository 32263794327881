import React from "react";

const Payment = () => {
  return (
    <>
      <div
        className="flex items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: "url('/images/Background_1.png')" }}
      >
        <div className="bg-[#24334B] w-96 h-96">

        </div>
      </div>
    </>
  );
};

export default Payment;
